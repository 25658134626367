<template>
  <div>
    <BaseCard heading="Shipment history">
      <template v-slot:headerActions>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </template>

      <v-data-table :headers="headers" :items="shipments" @click:row="onRowClick" :search="search">
        <template v-slot:[`item.shippingDate`]="{ item }">
          {{ formateDate(item.shippingDate, false) }}
        </template>

        <template v-slot:[`item.expectedDeliveryDate`]="{ item }">
          {{ formateDate(item.expectedDeliveryDate, false) }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-menu transition="slide-x-transition" bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon right v-bind="attrs" v-on="on"> mdi-dots-vertical </v-icon>
            </template>
            <v-list>
              <v-list-item link @click="selected.item = item">
                <v-list-item-title class="caption" @click="dialogVisible = true">Update Expected Date</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </BaseCard>
    <el-dialog title="Update Expected Delivery Date" :visible.sync="dialogVisible">
      <el-form ref="form" :model="form">
        <el-form-item label="New Date">
          <el-row :gutter="20">
            <el-col :span="11">
              <el-date-picker
                type="date"
                placeholder="Pick a date"
                v-model="selected.date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                style="width: 100%"
              ></el-date-picker>
            </el-col>

            <el-button :span="3" @click="dialogVisible = false">Cancel</el-button>
            <el-button :span="3" type="primary" @click="updateExpectedDate()">Confirm</el-button>
          </el-row>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { formatDate } from "../../../common/utils";
import BaseCard from "../../../components/BaseCard.vue";
import { getShipments, updateExpectedDeliveryDate } from "./lib";
export default {
  name: "History",
  components: { BaseCard },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.selected.date = "";
        this.selected.item = null;
      }
    },
  },
  computed: {},
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Shipping Date", value: "shippingDate" },
        { text: "AIR/SHIP No", value: "airShipNo" },
        { text: "Invoice No", value: "invoiceNo" },
        { text: "Co", value: "co" },
        { text: "Cartons", value: "cartons" },
        { text: "Via", value: "via" },
        { text: "Expected Delivery Date", value: "expectedDeliveryDate" },
        // { text: "Total Items", value: "total_items" },
        // { text: "Total Amount", value: "total_amt" },
        { text: "Actions", value: "actions" },
      ],
      shipments: [],
      search: "",
      selected: {
        item: null,
        date: "",
      },
      dialogVisible: false,
    };
  },
  methods: {
    formateDate: formatDate,
    onRowClick({ id }) {
      this.$router.push({
        name: "shipmentHistoryDetails",
        params: { id },
      });
    },
    async onInit() {
      this.$store.dispatch("setLoading", true);
      this.shipments = await getShipments();
      this.$store.dispatch("setLoading", false);
    },
    async updateExpectedDate() {
      this.$store.dispatch("setLoading", true);
      await updateExpectedDeliveryDate(this.selected.item.id, this.selected.date);
      await this.onInit();
      this.$notify({
        title: "Success",
        text: "Expected delivery date updated successfully.!",
        type: "success",
      });
      this.dialogVisible = false;
      this.$store.dispatch("setLoading", false);
    },
  },

  async mounted() {
    await this.onInit();
  },
};
</script>

<style></style>
