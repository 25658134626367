var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseCard',{attrs:{"heading":"Shipment history"},scopedSlots:_vm._u([{key:"headerActions",fn:function(){return [_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true}])},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.shipments,"search":_vm.search},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.shippingDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formateDate(item.shippingDate, false))+" ")]}},{key:"item.expectedDeliveryDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formateDate(item.expectedDeliveryDate, false))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"transition":"slide-x-transition","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.selected.item = item}}},[_c('v-list-item-title',{staticClass:"caption",on:{"click":function($event){_vm.dialogVisible = true}}},[_vm._v("Update Expected Date")])],1)],1)],1)]}}],null,true)})],1),_c('el-dialog',{attrs:{"title":"Update Expected Delivery Date","visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form}},[_c('el-form-item',{attrs:{"label":"New Date"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":11}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"type":"date","placeholder":"Pick a date","format":"yyyy-MM-dd","value-format":"yyyy-MM-dd"},model:{value:(_vm.selected.date),callback:function ($$v) {_vm.$set(_vm.selected, "date", $$v)},expression:"selected.date"}})],1),_c('el-button',{attrs:{"span":3},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("Cancel")]),_c('el-button',{attrs:{"span":3,"type":"primary"},on:{"click":function($event){return _vm.updateExpectedDate()}}},[_vm._v("Confirm")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }