import axios from "axios";
import { forceFileDownload } from "../../../common/utils";

async function getShipments() {
  try {
    const res = await axios.get(`shipping/all`);
    return res.data;
  } catch (error) {
    console.log(JSON.stringify(error));
  }
}

async function getShipmentDetail(id) {
  try {
    const res = await axios.get(`shipping/${id}`);
    return res.data;
  } catch (error) {
    console.log(JSON.stringify(error));
  }
}

async function downloadExportShippingDetails(data) {
  try {
    const response = await axios.post(`excel-generation/exportShippingDetails`, data, { responseType: "arraybuffer" });
    forceFileDownload(response, "Branch wise shipment details");
  } catch (error) {
    console.log(JSON.stringify(error));
  }
}

async function updateExpectedDeliveryDate(id, date) {
  try {
    const res = await axios.patch(`shipping/updateExpectedDD/${id}?date=${date}`);
    return res.data;
  } catch (error) {
    console.log(JSON.stringify(error));
  }
}

export { getShipments, getShipmentDetail, downloadExportShippingDetails, updateExpectedDeliveryDate };
